import { GetStaticProps } from 'next';
import { getCommonPageProps } from '@/shared/api';
import ErrorPageLayout from '@/shared/components/layout/ErrorPageLayout';
import { CommonPageProps } from '@/shared/lib/types';

const NotFoundPage = () => {
    return (
        <ErrorPageLayout
            title="Страница не найдена"
            text="Возможно неправильно набран адрес, или такой страницы больше не существует"
        />
    );
};

export default NotFoundPage;

export const getStaticProps: GetStaticProps<CommonPageProps> = async () => {
    const commonPageProps = await getCommonPageProps();

    return {
        props: {
            ...commonPageProps,
            meta: {
                ...commonPageProps.meta,
                title: 'Страница не найдена',
            },
            breadcrumbs: [],
            bodyClass: 'error-page',
        },
        revalidate: 120,
    };
};

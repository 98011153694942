import { useScroll } from 'framer-motion';
import { useRef } from 'react';
import DefaultLayout from '@/shared/components/layout/DefaultLayout';
import Button from '@/shared/components/shared/Button/Button';
import GradientBG from '@/shared/components/shared/GradientBG';
import Link from '@/shared/components/shared/Link';
import Typografed from '../../shared/Typografed';

interface Props {
    title: string;
    text?: string;
}

const ErrorPageLayout = ({ title, text }: Props) => {
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({ target: ref });

    return (
        <DefaultLayout ref={ref}>
            <section className="error-page-layour">
                <GradientBG scrollYProgress={scrollYProgress} />

                <div className="error-page-layour__wrapper">
                    <div className="error-page-layour__inner">
                        <div className="error-page-layour__inner-text">
                            <h1 className="error-page-layour__inner-text__title h4">
                                <Typografed>{title}</Typografed>
                            </h1>

                            {text && (
                                <p className="error-page-layour__inner-text__descr text-m">
                                    <Typografed>{text}</Typografed>
                                </p>
                            )}
                        </div>
                        <div className="error-page-layour__btn">
                            <Button tag={Link} href="/" variant="outline-light">
                                На главную
                            </Button>
                        </div>
                    </div>
                </div>
            </section>
        </DefaultLayout>
    );
};

export default ErrorPageLayout;
